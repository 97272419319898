import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    <strong>
                        Després de l'èxit de la convocatòria anterior per a l'accés a la Guàrdia
                        Urbana de Badalona 2023, on més del 90% dels alumnes aptes van ser de iOpos
                        Acadèmia, enguany et presentem un curs encara més complet i renovat.
                    </strong>
                </p>

                <p>Un curs en constant evolució i amb material de màxima qualitat.</p>

                <p>Dins del curs trobaràs:</p>

                <ul>
                    <li>
                        El temari de l'anterior convocatòria amb noves actualitzacions, idees força
                        i tests.
                    </li>
                    <li>Cultura general bàsica.</li>
                    <li>Cultura general avançada.</li>
                    <li>Notícies d'actualitat setmanals amb tests per posar-te a prova.</li>
                    <li>Un vídeo mensual d'actualitat.</li>
                    <li>El municipi en xifres.</li>
                    <li>El consistori.</li>
                    <li>7 exàmens oficials.</li>
                    <li>Simulacres per preparar-te al màxim!</li>
                </ul>

                <p>
                    <strong>Accedeix ara al curs més complet!</strong>
                </p>

                <p>El curs estarà obert fins al dia de l'examen.</p>

                <p>
                    <strong>Entra ara i avança al teu ritme!</strong>
                </p>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/9C8K3EgdRmo"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Converteix-te en agent de la Guàrdia Urbana de Badalona 2024 amb el curs més complet!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
